import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SignUpModal from '../GlobalLayout/SignUpModal/SignUpModal';

const CreateAccountPage = ({

}) => {

    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const codedEmail = searchParams.get('e');
    const email = codedEmail ? atob(codedEmail) : '';

    useEffect(() => {
        if (user) {
            navigate(`/timeline`)
        }
    }, []);

    const onClose = () => {
        navigate(`/timeline`)
    }

    return (
        <div className='bg-mid-grey'>
            <SignUpModal
                isOpen={true}
                onClose={onClose}
                showClose={false}
                showSignIn={false}
                lockedEmail={email}
                minimal
            />
        </div>
    )
}

export default CreateAccountPage;