import { useMutation } from '@apollo/client';
import { Button, Modal, ModalContent } from '@nextui-org/react';
import React, { useEffect, useRef, useState } from 'react';
import validator from 'validator';
import SIGN_UP from './mutations/signUp';
import { useDispatch } from 'react-redux';
import { setLocalToken } from '../../../utils/authUtils';
import { setUser } from '../../../store/slices/authSlice';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SignUpForm from './SignUpForm/SignUpForm';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { openSignInModal } from '../../../store/slices/uiSlice';
import { useMediaQuery } from '@uidotdev/usehooks';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const SignUpModal = ({
    isOpen,
    onClose,
    minimal,
    showClose,
    lockedEmail,
    showSignIn = true
}) => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState(lockedEmail ? lockedEmail : '');
    const onSetEmail = (val) => setEmail(val);

    const [password, setPassword] = useState('');
    const onSetPassword = (val) => setPassword(val);

    const [confirmPassword, setConfirmPassword] = useState('');
    const onSetConfirmPassword = (val) => setConfirmPassword(val);

    const [signUpError, setSignUpError] = useState(null);

    const [stripeIntentSecret, setStripeIntentSecret] = useState(null);
    
    const [subscribingUser, setSubscribingUser] = useState(null);
    const [subscribingUserToken, setSubscribingUserToken] = useState(null);

    const [plan, setPlan] = useState('GUEST');
    
    const onSetPro = () => {
        setPlan('PRO');
    }

    const [signUp, { loading, error, data }] = useMutation(SIGN_UP);

    const [screen, setScreen] = useState('SIGNUP_FORM');
    const onCheckout = () => setScreen('CHECKOUT');
    const onNavToForm = () => setScreen('SIGNUP_FORM');

    const stripeOptions = {
        // passing the client secret obtained from the server
        clientSecret: stripeIntentSecret,
        appearance: {
            variables: {
                fontSizeBase: '15px',
                fontWeightNormal: 500
            }
        }
    };

    const [proCycle, setProCycle] = useState('MONTHLY');
    const onSetMonthly = () => setProCycle('MONTHLY');
    const onSetAnnual = () => setProCycle('ANNUAL');

    const onSetGuest = () => {
        setPlan('GUEST');
        onSetMonthly();
    }

    const handleClose = () => {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        onNavToForm();
        onSetGuest();
        onClose();
    }

    const onToggleSignIn = () => {
        dispatch(openSignInModal());
    }

    const onSignUpFree = () => {
        signUp({
            variables: {
                email: email,
                password: password,
                plan: plan,
                cycle: proCycle
            },
            onCompleted: (data) => {

                if (data && data.signUp.error) {
                    setSignUpError(data.signUp.error);
                }

                if (data && data.signUp.token) {
                    setLocalToken(data.signUp.token);
                    dispatch(setUser(data.signUp.user));
                    setSignUpError(null);
                    handleClose();
                }
            }
        })
    }

    const onSignUpPro = () => {
        signUp({
            variables: {
                email: email,
                password: password,
                plan: plan,
                cycle: proCycle
            },
            onCompleted: (data) => {

                if (data && data.signUp.error) {
                    setSignUpError(data.signUp.error);
                }

                if (data && data.signUp.token) {
                    setLocalToken(data.signUp.token);
                    dispatch(setUser(data.signUp.user));
                    setSubscribingUser(data.signUp.user);
                    setSubscribingUserToken(data.signUp.token);
                    setStripeIntentSecret(data.signUp.paymentClientSecret)
                    setSignUpError(null);
                    onCheckout();
                }
            }
        })
    }

    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

    useEffect(() => {
        // Select all input and textarea elements within the component
        const inputs = document.querySelectorAll('input, textarea');
        
        // Function to scroll the input into view when focused
        const handleFocus = (event) => {
            setTimeout(() => {
                event.target.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }, 300); // Ensure it happens after the keyboard appears
        };
        
        // Add focus event listeners to all inputs and textareas
        inputs.forEach(input => {
            input.addEventListener('focus', handleFocus);
        });
        
        // Cleanup function to remove event listeners on unmount
        return () => {
            inputs.forEach(input => {
                input.removeEventListener('focus', handleFocus);
            });
        };
    }, []); // Empty dependency array to ensure this runs only once on mount

    let canSubmit = false;
    if (validator.isEmail(email) && password && password === confirmPassword) {
        canSubmit = true;
    }

    return (
        <Modal 
            isOpen={isOpen}
            onClose={showClose ? handleClose : null}
            scrollBehavior={isSmallDevice ? 'outside' : 'inside'}
            classNames={{
                body: 'px-0',
                base: 'px-0 modal',
                backdrop: !showClose ? "bg-mid-grey" : "",
                closeButton: !showClose ? "hidden" : "bg-white hover:bg-gray-100 active:bg-white/10"
            }}
        >
            <ModalContent className="py-4 max-h-[90vh] overflow-scroll text-black">
                <h2 className="font-bold px-4 tracking-tight mb-3 text-black">
                    Sign up for Lobby Report
                </h2>
                {screen === 'SIGNUP_FORM' && (
                    <SignUpForm
                        error={signUpError}
                        email={email}
                        disableEmail={lockedEmail || lockedEmail === '' ? true : false}
                        onSetEmail={onSetEmail}
                        password={password}
                        onSetPassword={onSetPassword}
                        confirmPassword={confirmPassword}
                        onSetConfirmPassword={onSetConfirmPassword}
                        onSetGuest={onSetGuest}
                        onSetPro={onSetPro}
                        plan={plan}
                        proCycle={proCycle}
                        onSetAnnual={onSetAnnual}
                        onSetMonthly={onSetMonthly}
                        minimal={minimal}
                    />
                )}
            
                {screen === 'CHECKOUT' && stripeIntentSecret && (
                    <Elements stripe={stripePromise} options={stripeOptions}
                    >
                        <CheckoutForm 
                            stripeOptions={stripeOptions}
                            stripePromise={stripePromise}
                            subscribingUser={subscribingUser}
                            subscribingUserToken={subscribingUserToken}
                            onBack={onNavToForm}
                        />
                    </Elements>
                )}
                
                <div className='px-4 mt-4'>
                    {screen === 'SIGNUP_FORM' && (
                        <Button 
                            className={!canSubmit ? "w-full font-semibold bg-gray-200 py-5 pointer-events-none opacity-60" : "w-full py-5 font-semibold"}
                            disableAnimation disableRipple
                            disabled={!canSubmit}
                            onClick={plan === 'GUEST' ? onSignUpFree : onSignUpPro}
                            isLoading={loading}
                        >
                            {plan === 'GUEST' && loading && 'Signing up...'}
                            {plan === 'GUEST' && !loading && `Sign up`}
                            {plan === 'PRO' && `Proceed to checkout`}
                        </Button>
                    )}
                </div>

                {showSignIn && (
                    <div className='text-center text-sm my-3 pt-3 border-top'>
                        <p onClick={onToggleSignIn} className='opacity-60 hover:opacity-90 cursor-pointer'>
                            Already have an account? Sign in
                        </p>
                    </div>
                )}

            </ModalContent>
        </Modal>
    )
}

export default SignUpModal;