import { useMutation } from '@apollo/client';
import { Button, Radio, RadioGroup } from '@nextui-org/react';
import axios from 'axios';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { REQUEST_INVITE } from '../GatePage/mutations/requestInvite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPeopleGroup, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DARK_GREY } from '../../utils/colours';

const SubscriptionsPage = ({

}) => {

    const [email, setEmail] = useState('');
    const onChangeEmail = (e) => setEmail(e.target.value);

    const isValidEmail = isEmail(email);

    const [status, setStatus] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('TEAM');

    const onSelectTeam = () => setSelectedPlan('TEAM');
    const onSelectEnterprise = () => setSelectedPlan('ENTERPRISE');
    const planClasses = "bg-offwhite rounded-none border border-transparent flex-grow"
    const selectedPlanClasses = "bg-white border border-mid-grey shadow rounded-none flex-grow"

    const [requestInvite, { loading, error, data }] = useMutation(REQUEST_INVITE);

    const onSubmit = (e) => {
        e.preventDefault();
        requestInvite({
            variables: {
                email: email,
                tier: selectedPlan
            },
            onCompleted: (data) => {
                if (data.requestInvite === true) {
                    setStatus('SUCCESS');
                }
            }
        })
    }

    return (
        <div className='mb-48'>
            <div className='bg-mid-grey relative'>
                <div className='w-5/6 lg:w-3/5 mx-auto h-[12vh] pb-3 flex flex-col justify-end relative'>
                    <p className='text-white text-3xl font-bold tracking-tight'>
                        Lobby Report
                    </p>            
                </div>  
                <div className='absolute top-2 right-2'>
                    <Link to='/sign-in'>
                        <Button
                            size='sm'
                            variant='flat'
                            className='font-medium text-white'
                        >
                            Sign in
                        </Button>
                    </Link>
                </div>  
            </div>
            <div className='w-5/6 lg:w-3/5 mx-auto py-8 mt-8 mb-24'>
                <h1 className='display-font pb-3'>
                    Subscribe to Lobby Report
                </h1>
                <div className='mb-8'>
                    <p className='font-bold leading-tight text-lg mb-2 max-w-80'>
                        Access privileged insights on Canadian public policy
                    </p>
                    <div className='mt-6'>
                        <p className='bg-mid-grey text-white inline-block px-12 py-1 font-bold mb-2'>
                            Plans
                        </p>
                        <div className='flex flex-col gap-2 md:flex-row'>
                            <div className='bg-offwhite flex-grow px-7 py-5'>
                                <p className='font-bold text-lg -mb-1'>
                                    Team
                                </p>
                                <p className='text-2xl display-font'>
                                    $2,950<span className='font-normal'>/year</span>
                                </p>
                                <p className='font-medium text-dark-grey mt-1 flex gap-1.5 items-center'>
                                    <FontAwesomeIcon icon={faUserGroup} size='xs' />
                                    Up to 25 users
                                </p>
                            </div>
                            <div className='bg-offwhite flex-grow px-7 py-5'>
                                <p className='font-bold text-lg -mb-1'>
                                    Enterprise
                                </p>
                                <p className='text-2xl display-font'>
                                    $3,950/year
                                </p>
                                <p className='font-medium text-dark-grey mt-1 flex gap-1.5 items-center'>
                                    <FontAwesomeIcon icon={faUsers} size='sm' />
                                    Unlimited users
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='text-dark-grey font-bold text-lg mb-2'>All plans enjoy...</p>
                    <div className='bg-offwhite flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Industry lists</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                    <div className='flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Topic Trackers</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                    <div className='my-4 border-b border-dotted border-mid-grey'></div>
                    <div>
                        <p className='font-bold text-sm mb-1'>Profiles</p>
                    </div>
                    <div className='bg-offwhite flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Government institutions</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                    <div className='flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Public office holders</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                    <div className='bg-offwhite flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Government relations firms</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                    <div className='flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Lobbyists</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                    <div className='bg-offwhite flex justify-between px-4 py-1 items-center'>
                        <p className='text-lg'>Companies & non-profits</p>
                        <FontAwesomeIcon icon={faCheck} color={DARK_GREY} />
                    </div>
                </div>
                
                <div className='mt-12 mb-6'>
                    <p className='font-bold text-xl mb-1'>
                        Subscribe
                    </p>
                    <p className='text-lg leading-tight lg:w-2/3'>
                        Fill out the form below and a member of our team will reach out within one business day to set you up with a subscription.
                    </p>
                </div>

                <div className='lg:w-1/2 flex flex-col gap-3'>
                    {status === '' && (
                        <form 
                            className='flex flex-col gap-4'
                            onSubmit={onSubmit}
                        >
                            <div>
                                <p className='font-bold mb-0.5 text-sm'>Email</p>
                                <input 
                                    className='border border-mid-grey px-4 py-2 shadow-md text-md w-full lg:w-4/5'
                                    placeholder='Enter your work email' 
                                    type='email'
                                    value={email}
                                    onChange={onChangeEmail}
                                />
                            </div>
                            

                            <div>
                                <p className='font-bold mb-0.5 text-sm'>Plan</p>
                                <div className='flex gap-2'>
                                    <Button 
                                        className={selectedPlan === 'TEAM' ? selectedPlanClasses : planClasses}
                                        onClick={onSelectTeam}
                                        disableAnimation disableRipple
                                    >
                                        Team
                                    </Button>
                                    <Button 
                                        className={selectedPlan === 'ENTERPRISE' ? selectedPlanClasses : planClasses}
                                        onClick={onSelectEnterprise}
                                        disableAnimation disableRipple
                                    >
                                        Enterprise
                                    </Button>
                                </div>
                            </div>
                            

                            <Button
                                className={!isValidEmail ? 'bg-mid-grey opacity-60 pointer-events-none text-white text-md cursor-not-allowed' : 'bg-mid-grey text-white text-md'}
                                disableAnimation
                                disableRipple
                                disabled={!isValidEmail}
                                type='submit'
                                isLoading={loading}
                                style={{
                                    borderRadius: 0
                                }}
                            >
                                Get Lobby Report
                            </Button>
                        </form>
                    )}
                    {status === 'SUCCESS' && (
                        <div className='bg-offwhite px-8 py-6 border border-mid-grey leading-snug font-medium text-center'>
                            Thank you for your interest. A member of our team will reach out to you soon.
                        </div>
                    )}
                </div>
            </div>
            
            
        </div>
    )

}

export default SubscriptionsPage;